import React, { useRef, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Rating,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import male from "./../Assets/img/reviews/male.webp";
import female from "./../Assets/img/reviews/female.webp";
import {
  reviewVariants,
  reviewContainer,
  containerVariants,
  cardVariants,
  container,
} from "./Reusable/FramerMotionVariants.tsx";
import { motion, Variants, AnimatePresence } from "framer-motion";



const reviews = [
  {
    name: "Sam Wilson",
    review:
      "Excellent service! My windows have never been so clean! Highly recommend!",
    image: male,
    rating: 5,
  },
  {
    name: "Jane Smith",
    review: "Very professional and thorough. Highly recommended!",
    image: female,
    rating: 5,
  },
  {
    name: "John Doe",
    review: "Great job and very friendly staff.",
    image: male,
    rating: 4,
  },
  {
    name: "Alex Johnson",
    review: "Quick and efficient service.",
    image: male,
    rating: 4,
  },
  {
    name: "Chris Lee",
    review: "Affordable and high-quality work.",
    image: male,
    rating: 5,
  },
  {
    name: "Pat Kim",
    review: "They were on time and very professional.",
    image: female,
    rating: 5,
  },
  {
    name: "Taylor Brown",
    review: "I will definitely use their service again.",
    image: female,
    rating: 5,
  },
  {
    name: "Jordan Davis",
    review: "My windows look amazing!",
    image: male,
    rating: 5,
  },
  {
    name: "Casey Martinez",
    review: "Highly recommend this company.",
    image: female,
    rating: 5,
  },
  // {
  //   name: "Morgan Clark",
  //   review: "Very satisfied with their work.",
  //   image: male,
  //   rating: 4,
  // },
];

const Reviews = () => {
  const scrollRef = useRef(null);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (scrollRef.current) {
      const middlePosition =
        (scrollRef.current.scrollWidth - scrollRef.current.clientWidth) / 2;
      scrollRef.current.scrollLeft = middlePosition;
    }
  }, []);

  const handleScroll = (distance) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + distance,
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    handleScroll(-345); // Adjust the scroll distance as needed
  };

  const handleNext = () => {
    handleScroll(345); // Adjust the scroll distance as needed
  };

  return (
    <Container id="reviews" sx={{ my: 5, textAlign: "center" }}>
      <motion.div
        className="card-container"
        variants={container}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.4 }}
      >
        <motion.div className="card" variants={cardVariants}>
          <Typography variant="h4" component="h2" color="#FF3EA5" sx={{ mb: 2 }}>
            Reviews
          </Typography>
        </motion.div>
        <motion.div className="card" variants={cardVariants}>
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton onClick={handlePrev} sx={{ mr: 1 }}>
              <ArrowBackIosIcon />
            </IconButton>
            <Box
              component={motion.div}
              display="flex"
              overflow="auto"
              ref={scrollRef}
              sx={{
                py: 1,
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "-ms-overflow-style": "none",
                "scrollbar-width": "none",
              }}
            >
              {reviews.map((review, index) => (
                <Card
                  key={index}
                  sx={{
                    maxWidth: 345,
                    mx: 1,
                    minWidth: 345,
                    boxSizing: "content-box",
                  }}
                >
                  <CardMedia
                    component="img"
                    height="140"
                    image={review.image}
                    alt={review.name}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {review.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {review.review}
                    </Typography>
                    <Rating
                      name={`review-rating-${index}`}
                      value={review.rating}
                      readOnly
                      precision={0.5}
                    />
                  </CardContent>
                </Card>
              ))}
            </Box>
            <IconButton onClick={handleNext} sx={{ ml: 1 }}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </motion.div>
      </motion.div>
    </Container>
  );
};

export default Reviews;
