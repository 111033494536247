import React, { useState, useEffect, useRef } from "react";
import { Container, Typography, Rating, Box, Grid, Button, Paper, Avatar, Checkbox, useMediaQuery, useTheme } from "@mui/material";
import "./SectionComponent.css";
import customerImage from "./../Assets/img/reviews/male.webp";
import backgroundImage from "./../Assets/img/house-2.webp";
import QuoteButton from  "./Reusable/QuoteButton.tsx"
import { motion, Variants } from "framer-motion";
import OutlinedButton from "./Reusable/OutlinedButton.tsx";
import CheckIcon from "@mui/icons-material/Check";
import CallIcon from '@mui/icons-material/Call';
import {reviewVariants, reviewContainer, cardVariants, container} from "./Reusable/FramerMotionVariants.tsx";

const SectionComponent: React.FC = () => {
  const theme = useTheme();
  // const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  // color: isSticky
  // ? theme.palette.common.white
  // : theme.palette.text.primary,

  return (
    <Container sx={{ mt: 8, mb: 8 }}>
      <Grid container spacing={6} alignItems="center">
        <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center", justifyItems: "center", justifyContent: "center" }}>
          <motion.div
            className="card-container"
            variants={container}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true , amount: 0.3 }}
            >
            
              <motion.div className="card" variants={cardVariants} >
                    <Typography sx={{ m: 0, p: 0 }} variant="h4" component="h2" gutterBottom>
                      Dirty Home? No Time?
                    </Typography>
                    
              </motion.div>

              <motion.div className="card" variants={cardVariants}>
                <Typography sx={{ mb: 2, p: 0 }} variant="h3" component="h2" gutterBottom>
                  <span className="highlight">Let Us Handle It!</span>
                </Typography>
              </motion.div>
                
              <motion.div className="card" variants={cardVariants}>
                <Typography variant="body1" sx={{textAlign: {xs: "justify"}}}>
                At QuackWash, we’re here to take the burden of exterior cleaning off your shoulders. Serving Austin and the greater Travis County area, we specialize in making your property shine with our professional services. From window cleaning to pressure washing your driveway, patio, fencing, and garage, we do it all. Our quack team is equipped with the latest tools and expertise to deliver outstanding results every time. With our competitive prices and excellent reputation, you can trust QuackWash to handle all your tough cleaning tasks, so you can focus on what matters most.
                </Typography>
              </motion.div>

              <Box sx={{ mt: 2 }}>

                <motion.div className="card" variants={cardVariants}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="body1">Licensed & Insured</Typography>
                  </Box>
                </motion.div>

                <motion.div className="card" variants={cardVariants}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="body1">Family Owned Company</Typography>
                  </Box>
                </motion.div>

                <motion.div className="card" variants={cardVariants}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                    <CheckIcon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="body1">100% Satisfaction Guaranteed</Typography>
                  </Box>
                </motion.div>

              <motion.div className="card flex self-end justify-between pt-8 flex-wrap" variants={cardVariants}>
                <QuoteButton sx={{ m: 1 }}>Get A Fast Quote</QuoteButton>
                <QuoteButton startIcon={<CallIcon />} sx={{ m: 1, color: theme.palette.mode === "light" ?"white" : "white", }} href="tel:+14095434333" className="flex w-full">
                  (409) 543-4333
                </QuoteButton>
              </motion.div>
            </Box>

            

          </motion.div>
        </Grid>

        <Grid item xs={12} md={6}>
            <motion.div
              className="reviewCard-container"
              // variants={reviewContainer}
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true , amount: 0.2 }}
            >
          <Box sx={{ position: "relative" }}>

            <img src={backgroundImage} alt="House" className="house-image" />


            <motion.div 
            className="reviewCard" 
            variants={reviewVariants} 
            // initial={{ opacity: 0, x: 150 }}
            // whileInView={{ opacity: 1, x: 0 }}

            >
                <Paper elevation={3} className="review-card">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Avatar src={customerImage} alt="Customer" sx={{ width: 56, height: 56, mr: 2 }} />
                    <Box>
                      <Typography variant="body1"><strong>Sam Wilson</strong></Typography>
                      <Typography variant="body2">Excellent service! My windows have never been so clean! Highly recommend!</Typography>
                      <Rating
                        name={`review-rating`}
                        value={5}
                        readOnly
                        precision={0.5}
                      />
                    </Box>
                  </Box>
                </Paper>
              </motion.div>
          </Box>
            </motion.div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SectionComponent;