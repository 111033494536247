import React from "react";
import { Box, Typography } from "@mui/material";

const Footer = () => (
  <Box
    sx={{
      bgcolor: "#FFFB00",
      color: "#FF3EA5",
      textAlign: "center",
      // py: 2,
      position: "relative",
      width: "100%",
    }}
  >
    <Typography variant="body1">
      &copy; 2024 QuackWash. All rights reserved.
    </Typography>
  </Box>
);

export default Footer;
