import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { useField, useFormik, FormikProvider, Form } from "formik";
import * as Yup from "yup";
import CheckIcon from "@mui/icons-material/Check";
import { GoogleMap, useLoadScript, Autocomplete } from "@react-google-maps/api";

const AddressInput = ({ label, helpText, ...props }) => {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [autocompleteRef, setAutocompleteRef] = useState(null);

  // Load Google Maps API for Places
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBKREoH_m5blv1HfP125XF018JdcvUQn7g", // Replace with your Google Maps API key
    libraries: ["places"],
  });

  useEffect(() => {
    if ((didFocus && field.value.trim().length > 0) || meta.touched) {
      setShowFeedback(true);
    }
  }, [didFocus, meta.touched, field.value, meta.error]);

  const handlePlaceSelect = () => {
    if (autocompleteRef && typeof autocompleteRef.getPlace === "function") {
      const place = autocompleteRef.getPlace();
      if (place && place.formatted_address) {
        field.onChange({
          target: { name: field.name, value: place.formatted_address },
        });
      }
    }
  };

  // if (loadError) {
  //   return <div>Error loading maps</div>;
  // }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body1" component="label" htmlFor={props.id}>
        {label}
      </Typography>
      <Autocomplete
        onLoad={(ref) => setAutocompleteRef(ref)}
        onPlaceChanged={handlePlaceSelect}
        options={{
          bounds: {
            north: 30.594, // Approximate northern bound of Travis County
            south: 30.073, // Approximate southern bound of Travis County
            east: -97.315, // Approximate eastern bound of Travis County
            west: -98.041, // Approximate western bound of Travis County
          },
          // strictBounds: true,
          // componentRestrictions: { country: "us", administrativeArea: "TX" },
        }}
      >
        <TextField
          {...props}
          {...field}
          variant="outlined"
          fullWidth
          placeholder={helpText}
          aria-describedby={`${props.id}-feedback ${props.id}-help`}
          onFocus={() => setDidFocus(true)}
          error={showFeedback && Boolean(meta.error)}
          FormHelperTextProps={{
            sx: {
              color: showFeedback && (meta.error ? "#FF3EA5" : "#4CAF50"),
              textAlign: "center",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderWidth: "2px",
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&:hover fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
              "&.Mui-focused fieldset": {
                borderColor: showFeedback
                  ? meta.error
                    ? "#d32f2f"
                    : "#4CAF50"
                  : "#FF3EA5",
              },
            },
          }}
          helperText={
            <Typography
              variant="caption"
              component="div"
              className={`feedback ${showFeedback ? "" : "invisible"}`}
              sx={{ textAlign: "center" }}
            >
              {meta.error ? meta.error : "✓"}
            </Typography>
          }
        />
      </Autocomplete>
    </Box>
  );
};

const TextInputLiveFeedback = ({ label, helpText, ...props }) => {
  const [field, meta] = useField(props);
  const [didFocus, setDidFocus] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    if ((didFocus && field.value.trim().length > 0) || meta.touched) {
      setShowFeedback(true);
    }
  }, [didFocus, meta.touched, field.value, meta.error]);

  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body1" component="label" htmlFor={props.id}>
        {label}
      </Typography>
      <TextField
        {...props}
        {...field}
        variant="outlined"
        fullWidth
        multiline={props.type === "textarea"}
        rows={props.type === "textarea" ? 4 : undefined}
        placeholder={helpText}
        aria-describedby={`${props.id}-feedback ${props.id}-help`}
        onFocus={() => setDidFocus(true)}
        error={showFeedback && Boolean(meta.error)}
        FormHelperTextProps={{
          sx: {
            color: showFeedback && (meta.error ? "#FF3EA5" : "#4CAF50"),
            textAlign: "center",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderWidth: "2px",
              borderColor: showFeedback
                ? meta.error
                  ? "#d32f2f"
                  : "#4CAF50"
                : "#FF3EA5",
            },
            "&:hover fieldset": {
              borderColor: showFeedback
                ? meta.error
                  ? "#d32f2f"
                  : "#4CAF50"
                : "#FF3EA5",
            },
            "&.Mui-focused fieldset": {
              borderColor: showFeedback
                ? meta.error
                  ? "#d32f2f"
                  : "#4CAF50"
                : "#FF3EA5",
            },
          },
          ...(props.type === "textarea" && {
            mb: 0,
          }),
        }}
        helperText={
          <Typography
            variant="caption"
            component="div"
            className={`feedback ${showFeedback ? "" : "invisible"}`}
            sx={{ textAlign: "center" }}
          >
            {meta.error ? meta.error : "✓"}
          </Typography>
        }
      />
    </Box>
  );
};

TextInputLiveFeedback.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
};

AddressInput.propTypes = {
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Contact = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.offsetHeight);
      console.log("height", containerRef.current.offsetHeight);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      address: "", // Add address to form values
      message: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Too Short!")
        .max(50, "Too Long!")
        .required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      address: Yup.string(),
      message: Yup.string()
        .min(10, "Must be at least 10 characters!")
        .required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setErrorMessage("");
      await sleep(500);

      try {
        const response = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            access_key: "acbca84d-2890-4543-8e53-44547bab6ac1",
            ...values,
          }),
        });

        if (response.ok) {
          setFormSubmitted(true);
          resetForm();
        } else {
          throw new Error("Form submission failed");
        }
      } catch (error) {
        setErrorMessage(error.message);
        setFormSubmitted(true);
      } finally {
        setIsLoading(false);
      }
    },
  });

  return (
    <Container id="contact" sx={{ my: 4, textAlign: "center" }}>
      <Typography variant="h4" component="h2" color="#FF3EA5" sx={{ mb: 1 }}>
        Contact Us
      </Typography>
      <Typography variant="body1">
        Phone:{" "}
        <a href="tel:+14095434333" style={{ color: "#FF3EA5" }}>
          (409) 543-4333
        </a>
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Email:{" "}
        <a href="mailto:contact@quackwash.com" style={{ color: "#FF3EA5" }}>
          contact@quackwash.com
        </a>
      </Typography>
      <FormikProvider value={formik}>
        <Box className="card cardForm">
          {formSubmitted ? (
            errorMessage ? (
              <Box
                className="formError"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: `${containerHeight}px`,
                }}
              >
                <Box className="errorIcon" sx={{ fontSize: 30 }}>
                  😖
                </Box>
                <Typography variant="h5" sx={{ marginBlockEnd: 1 }}>
                  Yikes! An error occurred.
                </Typography>
                <Typography variant="h6" sx={{ marginBlockEnd: 2 }}>
                  Error: {errorMessage}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setFormSubmitted(false);
                    setErrorMessage("");
                  }}
                >
                  Try Again
                </Button>
              </Box>
            ) : (
              <Box
                className="formSuccess"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: `${containerHeight}px`,
                }}
              >
                <Typography variant="h5" className="thankYouMessage">
                  Thank You!
                </Typography>
                <Box className="successMessage">
                  <Typography variant="h6">
                    The form has been submitted!
                  </Typography>
                  <CheckIcon
                    color="success"
                    fontSize="large"
                    sx={{ marginBlockEnd: 2 }}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setFormSubmitted(false)}
                >
                  Submit Another Form
                </Button>
              </Box>
            )
          ) : isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: `${containerHeight}px`,
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Form className="formBody" ref={containerRef}>
              <TextInputLiveFeedback
                label="Name"
                id="name"
                name="name"
                type="text"
                helpText="Please enter your full name"
              />
              <TextInputLiveFeedback
                label="Email"
                id="email"
                name="email"
                type="email"
                helpText="We'll never share your email with anyone else"
              />

              {/* Autocomplete Address Input */}

              <AddressInput
                label="Address"
                id="address"
                name="address"
                type="address"
                helpText="Please enter your address (Not required)"
              />
              <TextInputLiveFeedback
                label="Message"
                id="message"
                name="message"
                type="textarea"
                helpText="Please enter your message"
              />
              <Button
                type="submit"
                variant="contained"
                sx={{ backgroundColor: "#FF3EA5", color: "white", mt: 2 }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Box>
      </FormikProvider>
    </Container>
  );
};

export default Contact;
